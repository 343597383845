
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































.wrapper {
  width: 100%;
  padding: 0 20px;
  max-width: 1600px;

  @include mq(m) {
    padding: 0 40px;
  }
}

.head {
  text-align: center;
}

.head__label {
  @include fluid(
    font-size,
    (
      xxs: 14px,
      l: 18px,
    )
  );
  color: $dusty-gray;
}

.head__date {
  @include fluid(
    font-size,
    (
      xxs: 14px,
      l: 18px,
    )
  );
  font-weight: 400;
}

.head__title {
  text-align: center;
  font-weight: 400;
  line-height: 1;
  @include fluid(
    font-size,
    (
      xs: 28px,
      xl: 94px,
    )
  );
}

.head__picture {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;

  img {
    @include image-fit;
    transition: transform 0.3s;
  }
}

.related {
}

.related__title {
  text-align: center;
  font-weight: 400;
  @include fluid(
    font-size,
    (
      xs: 20px,
      xl: 24px,
    )
  );
}

@include mq(s) {
  .related__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
      flex-basis: 46%;
    }
  }
}

hr {
  border: 1px solid #f0f0f0;
}
